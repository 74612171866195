import React, { useState } from 'react';
import { Field, ErrorMessage } from 'formik';
import openEye from '../../assets/Eye_open.svg';
import closeEye from '../../assets/Eye_close.svg';
import styles from './InputField.module.css';

function InputField({
  type = 'text',
  placeholder = '',
  name,
  label,
  error,
  required = false,
  disabled = false,
  className = '',
  showPasswordToggle = false,
}) {
  const [passwordVisible, setPasswordVisible] = useState(false);

  const handleToggleVisibility = () => {
    setPasswordVisible((prevState) => !prevState);
  };

  return (
    <div className={`${styles.inputContainer} ${className}`}>
      {label && <label className={styles.label}>{label}</label>}
      <Field
        type={showPasswordToggle && passwordVisible ? 'text' : type}
        name={name}
        placeholder={placeholder}
        required={required}
        disabled={disabled}
        className={`${styles.input} ${error ? styles.errorInput : ''}`}
      />
      {showPasswordToggle && type === 'password' && (
        <img
          onClick={handleToggleVisibility}
          src={passwordVisible ? openEye : closeEye}
          alt={passwordVisible ? 'Hide password' : 'Show password'}
          className={styles.passwordIcon}
        />
      )}
      <ErrorMessage name={name} component="span" className={styles.error} />
    </div>
  );
}

export default InputField;
