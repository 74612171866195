import React from 'react';
import './Home.css'; // Custom styles
import Footer from '../components/Footer';
const Home = () => {
  return (
    <div
      className="app-container"
      style={{ backgroundImage: `url(/assets/Background.png)` }}
    >
      <div className="d-flex justify-content-center">
        <img className="logo" src="/assets/logo.png" alt="Logo" />
      </div>
      <p className="app-heading">
        To Love is to create a LifeWink.
        <br />
        To be Loved is to receive a LifeWink.
      </p>
      <p className="app-sideheading">
        LifeWink enables you to deliver life's greatest gift - to love and be
        loved - by creating <br />
        timeless private LifeWinks with your videos, photos, audio, and
        attachments.
      </p>
      {/* Added img-fluid to make the image responsive */}
      {/* <div className="d-flex justify-content-center">
        <img className="img-fluid container-image" src="/assets/Container.png" alt="Container" />
      </div> */}
      <img className="container-image" src="./assets/Container.png"></img>
      <Footer />
    </div>
  );
};

export default Home;
