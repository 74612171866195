import React from 'react';
import styles from './SearchField.module.css';

function SearchField({
  placeholder = 'Search...', // Placeholder for the input field
  value,
  onChange,
  onSearch, // Callback triggered on Enter key press
  onIconClick,
  disabled = false,
  loading = false,
  className,
  iconSrc = null, // URL for PNG or other images
  iconAlt = 'search icon',
  iconPosition = 'left', // 'left' or 'right'
}) {
  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && onSearch) {
      onSearch(value);
    }
  };

  return (
    <div className={`${styles.searchField} ${className}`}>
      {iconSrc && iconPosition === 'left' && (
        <img
          src={iconSrc}
          alt={iconAlt}
          className={styles.icon}
          onClick={onIconClick}
        />
      )}
      <input
        type="text"
        placeholder={loading ? 'Loading...' : placeholder}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        onKeyDown={handleKeyDown}
        disabled={disabled}
        className={styles.input}
      />
      {iconSrc && iconPosition === 'right' && (
        <img
          src={iconSrc}
          alt={iconAlt}
          className={styles.icon}
          onClick={onIconClick}
        />
      )}
    </div>
  );
}

export default SearchField;
