import styles from './Modal.module.css'; // We'll define the styles for the modal here.

import ReactDOM from 'react-dom';

function Modal({ children, closeModal, showCloseButton = true }) {
  return ReactDOM.createPortal(
    <div className={styles.overlay} onClick={closeModal}>
      <div className={styles.modal} onClick={(e) => e.stopPropagation()}>
        {showCloseButton && (
          <button className={styles.closeButton} onClick={closeModal}>
            &times;
          </button>
        )}
        {children}
      </div>
    </div>,
    document.body, // Render modal at the root of the body
  );
}

export default Modal;
