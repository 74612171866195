import React, { useState } from 'react';
import PropTypes from 'prop-types';

import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRange } from 'react-date-range';
import { addDays, subDays } from 'date-fns';

const Calendar = ({ onChange }) => {
  const [state, setState] = useState([
    {
      startDate: subDays(new Date(), 7),
      endDate: addDays(new Date(), 1),
      key: 'selection',
    },
  ]);

  const handleOnChange = (ranges) => {
    const { selection } = ranges;
    onChange(selection);
    setState([selection]);
  };

  return (
    <DateRange
      onChange={handleOnChange}
      displayMode="dateRange"
      showSelectionPreview={false}
      moveRangeOnFirstSelection={false}
      months={1}
      ranges={state}
      direction="horizontal"
      dateDisplayFormat="dd MMM, yyy"
      editableDateInputs
      retainEndDateOnFirstSelection
      minDate={new Date('2023-01-01')}
      maxDate={new Date()}
    />
  );
};

Calendar.propTypes = {
  onChange: PropTypes.func,
};

export default Calendar;
