import React, { useState, useEffect, useReducer, useRef } from 'react';

import 'react-datepicker/dist/react-datepicker.css';

import {
  startOfDay,
  endOfDay,
  startOfWeek,
  startOfMonth,
  format,
  set,
} from 'date-fns';

import Papa from 'papaparse';

import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

import Dropdown from '../../components/dropdown/Dropdown';
import Button from '../../components/button/Button';
import filter from '../../assets/feedback/filter-lines.svg';
import close from '../../assets/feedback/x-close.svg';
import download from '../../assets/feedback/download.svg';
import styles from './Feedback.module.css';
import SearchField from '../../components/searchField/SearchField';
import Loader from '../../components/loader/Loader';
import { fetchFeedbackData } from '../../api/adminApi';
import Calendar from '../../components/calender/Calender';
import { useNavigate } from 'react-router-dom';
import { fetchAuthSession } from '@aws-amplify/auth';
import CustomDropdown from '../../components/customDropdown/CustomDropdown';

// Define constants
const dateOptions = [
  { value: 'all', label: 'All' },
  { value: 'today', label: 'Today' },
  { value: 'thisWeek', label: 'This Week' },
  { value: 'thisMonth', label: 'This Month' },
  { value: 'thisYear', label: 'This Year' },
  { value: 'custom', label: 'Custom' },
];

const experienceOptions = [
  { value: '0', label: 'Good' },
  { value: '1', label: 'Average' },
  { value: '2', label: 'Bad' },
];

const versionOptions = [{ value: '1.0.0', label: '1.0.0' }];

// Initial state for useReducer
const initialState = {
  users: [],
  loading: true,
  error: null,
  searchValue: '',
  showFilterDropdown: false,
  selectedFilters: { experience: '', appVersion: '', date: 'all' },
  currentPage: { pageNumber: 1, lastEvaluatedKey: {} },
  lastEvaluatedKeyHistory: [],
  itemsPerPage: 10,
  totalPages: 1,
  fetchCompleteData: false,
};
function feedbackReducer(state, action) {
  switch (action.type) {
    case 'SET_USERS':
      return { ...state, users: action.payload };
    case 'SET_LOADING':
      return { ...state, loading: action.payload };
    case 'SET_ERROR':
      return { ...state, error: action.payload };
    case 'SET_SEARCH_VALUE':
      return { ...state, searchValue: action.payload };
    case 'TOGGLE_FILTER_DROPDOWN':
      return { ...state, showFilterDropdown: action.payload };
    case 'SET_FILTERS':
      return { ...state, selectedFilters: action.payload };
    case 'SET_CURRENT_PAGE': {
      const isNextPage =
        action.payload.pageNumber >= state.currentPage.pageNumber;

      // Update history for new pages or keep it consistent when going back
      const updatedHistory = isNextPage
        ? [
            ...state.lastEvaluatedKeyHistory.filter(
              (entry) => entry.pageNumber !== action.payload.pageNumber,
            ), // Ensure no duplicate entries
            {
              pageNumber: action.payload.pageNumber,
              lastEvaluatedKey: action.payload.lastEvaluatedKey,
            },
          ]
        : state.lastEvaluatedKeyHistory;

      return {
        ...state,
        currentPage: {
          pageNumber: action.payload.pageNumber,
          lastEvaluatedKey: action.payload.lastEvaluatedKey,
        },
        lastEvaluatedKeyHistory: updatedHistory,
      };
    }
    case 'RESET_LAST_EVALUATED_KEY_HISTORY':
      return {
        ...state,
        lastEvaluatedKeyHistory: [], // Reset only this field
      };
    case 'TOTAL_PAGES':
      return { ...state, totalPages: action.payload };
    default:
      return state;
  }
}

function Feedback() {
  const [startDate, setStartDate] = useState(
    new Date('2023-01-01').toISOString(),
  );
  const navigate = useNavigate();
  const wrapperRef = useRef(null);
  const [endDate, setEndDate] = useState(null);
  const [customCalendar, setCustomCalendar] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [state, dispatch] = useReducer(feedbackReducer, initialState);
  const [backWordPage, setBackWordPage] = useState(false);
  const {
    users,
    loading,
    error,
    searchValue,
    showFilterDropdown,
    selectedFilters,
    currentPage,
    itemsPerPage,
    totalPages,
  } = state;

  useEffect(() => {
    const validateSession = async () => {
      try {
        const session = await fetchAuthSession();
        if (session.credentials) {
          setIsAuthorized(true); // Mark as authorized if session is valid
        }
      } catch (err) {
        console.error('Error validating session:', err);
        navigate('/admin/login'); // Redirect in case of an error
      }
    };

    validateSession();
  }, []);

  useEffect(() => {
    if (!isAuthorized) return;
    console.log(
      currentPage.lastEvaluatedKey &&
        currentPage.pageNumber > 1 &&
        Object.keys(currentPage.lastEvaluatedKey).length > 0
        ? JSON.stringify(currentPage.lastEvaluatedKey)
        : null,
    );
    console.log(currentPage.pageNumber, 'currentPage.pageNumber');
    console.log(currentPage.lastEvaluatedKey, 'currentPage.lastEvaluatedKey');

    const fetchFeedbackWithUsers = async () => {
      try {
        const params = {
          page: currentPage.pageNumber,
          limit: itemsPerPage,
          startDate,
          endDate,
          appVersion: selectedFilters.appVersion,
          lifeWinkExperience: selectedFilters.experience
            ? Number(selectedFilters.experience)
            : null,
          search: searchValue,
          lastEvaluatedKey:
            currentPage.lastEvaluatedKey &&
            currentPage.pageNumber > 1 &&
            Object.keys(currentPage.lastEvaluatedKey).length > 0
              ? JSON.stringify(currentPage.lastEvaluatedKey)
              : null,
        };
        if (currentPage.pageNumber === 1 && searchValue) {
          dispatch({ type: 'SET_LOADING', payload: true });
        }
        const feedback = await fetchFeedbackData(params);

        const feedbackWithUsers = feedback.feedback;
        const newLastEvaluatedKey = feedback.lastEvaluatedKey;

        if (
          (newLastEvaluatedKey &&
            JSON.stringify(newLastEvaluatedKey) !==
              JSON.stringify(currentPage.lastEvaluatedKey) &&
            backWordPage === false) ||
          currentPage.pageNumber === 1
        ) {
          console.log("I'm here");
          console.log(newLastEvaluatedKey, 'newLastEvaluatedKey');
          console.log(currentPage.pageNumber, 'currentPage.pageNumber');

          dispatch({
            type: 'SET_CURRENT_PAGE',
            payload: {
              pageNumber: currentPage.pageNumber,
              lastEvaluatedKey: newLastEvaluatedKey,
            },
          });
        }

        dispatch({ type: 'SET_USERS', payload: feedbackWithUsers });
        dispatch({ type: 'TOTAL_PAGES', payload: feedback.totalPages });
        if (feedback.totalPages === 1) {
          dispatch({
            type: 'SET_CURRENT_PAGE',
            payload: { pageNumber: 1, lastEvaluatedKey: {} },
          });
        }
      } catch (err) {
        dispatch({
          type: 'SET_ERROR',
          payload: `Failed to fetch feedback and users: ${err.message}`,
        });
      } finally {
        dispatch({ type: 'SET_LOADING', payload: false });
      }
    };

    fetchFeedbackWithUsers();
  }, [
    startDate,
    endDate,
    searchValue,
    isAuthorized,
    currentPage.pageNumber, // Depend only on page number for triggering fetch
    selectedFilters.appVersion,
    selectedFilters.experience,
    backWordPage,
  ]);

  useEffect(() => {
    if (selectedFilters.date === 'today') {
      setStartDate(startOfDay(new Date()).toISOString());
      setEndDate(endOfDay(new Date()).toISOString());
    }
    if (selectedFilters.date === 'thisWeek') {
      setStartDate(startOfWeek(new Date()).toISOString());
      setEndDate(endOfDay(new Date()).toISOString());
    }
    if (selectedFilters.date === 'thisMonth') {
      setStartDate(startOfMonth(new Date()).toISOString());
      setEndDate(endOfDay(new Date()).toISOString());
    }
    if (selectedFilters.date === 'thisYear') {
      setStartDate(set(new Date(), { month: 0, date: 1 }).toISOString());
      setEndDate(endOfDay(new Date()).toISOString());
    }
    if (selectedFilters.date === 'all') {
      setStartDate(new Date('2023-01-01').toISOString());
      setEndDate(null);
    }
  }, [selectedFilters]);

  useEffect(() => {
    if (selectedFilters.date === 'custom') {
      const convertedStartDate = new Date(startDate);
      const convertedEndDate = new Date(endDate);

      // Validate that both startDate and endDate are valid
      const validStartDate = !isNaN(convertedStartDate.getTime());
      const validEndDate = !isNaN(convertedEndDate.getTime());

      if (validStartDate && validEndDate) {
        // If both dates are valid, update the label
        dateOptions[5].label = `${format(convertedStartDate, 'MM/dd/yyyy')} - ${format(convertedEndDate, 'MM/dd/yyyy')}`;
      } else {
        dateOptions[5].label = 'Custom';
      }
    }
  }, [selectedFilters.date, startDate, endDate]);
  // Handle filter change
  const handleFilterChange = (filterType, value) => {
    dispatch({ type: 'RESET_LAST_EVALUATED_KEY_HISTORY' });
    dispatch({
      type: 'SET_CURRENT_PAGE',
      payload: { pageNumber: 1, lastEvaluatedKey: {} },
    });
    const updatedFilters = { ...selectedFilters, [filterType]: value };
    value === 'custom'
      ? setCustomCalendar(!customCalendar)
      : setCustomCalendar(false);
    dateOptions[5].label = 'Custom';
    dispatch({ type: 'SET_FILTERS', payload: updatedFilters });
  };
  const clearFilter = (filterType) => {
    dispatch({ type: 'RESET_LAST_EVALUATED_KEY_HISTORY' });
    dispatch({
      type: 'SET_CURRENT_PAGE',
      payload: { pageNumber: 1, lastEvaluatedKey: {} },
    });
    const updatedFilters = { ...selectedFilters, [filterType]: '' };
    dispatch({ type: 'SET_FILTERS', payload: updatedFilters });
  };
  const handlePageChange = (pageNumber) => {
    const { lastEvaluatedKeyHistory } = state;

    const cachedKeyEntry = lastEvaluatedKeyHistory.find(
      (entry) => entry.pageNumber === pageNumber - 1,
    );

    let lastEvaluatedKey = {};

    // Use cached key if available, otherwise fallback
    if (cachedKeyEntry) {
      lastEvaluatedKey = cachedKeyEntry.lastEvaluatedKey;
    } else if (pageNumber === 1) {
      // Default key for the first page
      lastEvaluatedKey = {};
    }
    // Dispatch the action to update the current page
    dispatch({
      type: 'SET_CURRENT_PAGE',
      payload: { pageNumber, lastEvaluatedKey },
    });
  };

  const applySelectedFilters = () => {
    dispatch({ type: 'RESET_LAST_EVALUATED_KEY_HISTORY' });
    dispatch({
      type: 'SET_CURRENT_PAGE',
      payload: { pageNumber: 1, lastEvaluatedKey: {} },
    });
    dispatch({ type: 'TOGGLE_FILTER_DROPDOWN', payload: false });
    dispatch({ type: 'SET_FILTERS', payload: selectedFilters });
  };

  const resetFilters = () => {
    dispatch({ type: 'RESET_LAST_EVALUATED_KEY_HISTORY' });
    dispatch({
      type: 'SET_CURRENT_PAGE',
      payload: { pageNumber: 1, lastEvaluatedKey: {} },
    });
    dispatch({ type: 'TOGGLE_FILTER_DROPDOWN', payload: false });
    dispatch({
      type: 'SET_FILTERS',
      payload: { date: '', experience: '', appVersion: '' },
    });
  };

  const handleClickOutside = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setCustomCalendar(false);
      console.log(showFilterDropdown, 'showFilterDropdown');

      dispatch({
        type: 'TOGGLE_FILTER_DROPDOWN',
        payload: false,
      });
    }
  };
  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, false);
    };
  }, []);

  const downloadCSV = async () => {
    const params = {
      page: currentPage.pageNumber,
      limit: itemsPerPage,
      startDate: startDate,
      endDate: endDate,
      appVersion: selectedFilters.appVersion,
      lifeWinkExperience: selectedFilters.experience
        ? Number(selectedFilters.experience)
        : null,
      search: searchValue,
      fetchCompleteData: true,
    };
    dispatch({ type: 'SET_LOADING', payload: true });

    const feedback = await fetchFeedbackData(params);
    const feedbackWithUsers = feedback.feedback;

    const csvData = feedbackWithUsers.map((user) => ({
      Date_Time: format(new Date(user.createdAt), 'MMMM dd, yyyy HH:mm'),
      User_Details: user.userDetails || '',
      App_Version: user.appVersion || '',
      Device_Model: user.deviceModel || '',
      OS_Version: user.osVersion || '',
      Experience:
        user.lifeWinkExperience === 0
          ? 'Good'
          : user.lifeWinkExperience === 1
            ? 'Average'
            : user.lifeWinkExperience === 2
              ? 'Bad'
              : '',
      Improvements: Array.isArray(user.improvementAreas)
        ? user.improvementAreas.join(', ')
        : user.improvementAreas || '',
      Message: user.suggestions || '',
    }));

    const csvHeader = [
      { label: 'Date/Time', key: 'Date_Time' },
      { label: 'User Details', key: 'User_Details' },
      { label: 'App Version', key: 'App_Version' },
      { label: 'OS Version', key: 'OS_Version' },
      { label: 'Device Model', key: 'Device_Model' },
      { label: 'Experience', key: 'Experience' },
      { label: 'Improvements', key: 'Improvements' },
      { label: 'Message', key: 'Message' },
    ];
    const csvBlob = new Blob(
      [Papa.unparse({ fields: csvHeader.map((h) => h.key), data: csvData })],
      {
        type: 'text/csv;charset=utf-8;',
      },
    );
    const currentDate = new Date();

    const formattedDate = currentDate.toISOString().split('T')[0];
    const url = window.URL.createObjectURL(csvBlob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `feedbacks-${formattedDate}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    dispatch({ type: 'SET_LOADING', payload: false });
  };

  const experienceMapping = {
    0: {
      label: 'Good',
      styles: { backgroundColor: '#ECF8F1', color: '#05973F' },
    },
    1: {
      label: 'Average',
      styles: { backgroundColor: '#F7F1E6', color: '#E78D00' },
    },
    2: {
      label: 'Bad',
      styles: { backgroundColor: '#FDE5E7', color: '#FF3A44' },
    },
  };

  if (loading) {
    return (
      <div className={styles.container}>
        <div className={styles.loaderWrapper}>
          <Loader />
        </div>
      </div>
    );
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  const customDateChange = (date) => {
    const currentDate = new Date();
    const selectedStartDate = new Date(date.startDate);
    const selectedEndDate = new Date(date.endDate);

    if (selectedStartDate > currentDate) return;
    if (selectedEndDate > currentDate) return;
    if (selectedEndDate < selectedStartDate) return;

    setStartDate(selectedStartDate.toISOString());
    setEndDate(selectedEndDate.toISOString());
  };
  const handleSearch = () => {
    const lowercaseQuery = searchQuery.trim().toLowerCase();
    if (lowercaseQuery.trim()) {
      dispatch({ type: 'SET_SEARCH_VALUE', payload: lowercaseQuery });
    } else {
      dispatch({ type: 'SET_SEARCH_VALUE', payload: '' });
    }
  };

  const handleInputChange = (value) => {
    setSearchQuery(value);

    if (value.trim() === '') {
      dispatch({ type: 'SET_SEARCH_VALUE', payload: '' });
      dispatch({
        type: 'SET_CURRENT_PAGE',
        payload: { pageNumber: 1, lastEvaluatedKey: {} },
      });
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div>
          <h1>Feedback</h1>
          <p>Review the insights and suggestions here.</p>
        </div>
        <div className={styles.buttonWrap}>
          <div>
            <SearchField
              placeholder="Search user details"
              value={searchQuery}
              onChange={(value) => handleInputChange(value)}
              iconPosition="right"
              className={styles.searchField}
              iconSrc={searchQuery ? close : null}
              onIconClick={() => handleInputChange('')}
            />
            <Button
              className={styles.searchButton}
              onClick={handleSearch}
              disabled={searchQuery.trim() === ''}
            >
              Search
            </Button>
          </div>

          <div ref={wrapperRef}>
            <div className={styles.dropdownWrapper}>
              {/* <Dropdown
                options={dateOptions}
                onSelect={(value) => handleFilterChange('date', value)}
                disabled={false}
                loading={false}
                placeholder="Select"
                value={selectedFilters.date || ''}
              /> */}
              <CustomDropdown
                options={dateOptions}
                onSelect={(value) => {
                  handleFilterChange('date', value);
                }}
                onToggle={() => {
                  dispatch({
                    type: 'TOGGLE_FILTER_DROPDOWN',
                    payload: false,
                  });
                }}
                value={selectedFilters.date || ''}
                placeholder="Select an option"
                className={styles.filterByDate}
              />
            </div>
            {customCalendar && (
              <div className={styles.dateRange}>
                <Calendar onChange={customDateChange} />
              </div>
            )}
            <Button
              iconSrc={filter}
              className={styles.filterButton}
              onClick={() =>
                dispatch({
                  type: 'TOGGLE_FILTER_DROPDOWN',
                  payload: !showFilterDropdown,
                })
              }
            >
              Filters
            </Button>
            {showFilterDropdown && (
              <div className={styles.filterDropdown}>
                <div className={styles.dropdownHeader}>
                  <p>Filter</p>
                  <img
                    src={close}
                    alt=""
                    onClick={() =>
                      dispatch({
                        type: 'TOGGLE_FILTER_DROPDOWN',
                        payload: false,
                      })
                    }
                  />
                </div>
                <div className={styles.dropdownWrapper}>
                  <div className={styles.filterDropdownHeader}>
                    <p>Experience</p>
                    <span onClick={() => clearFilter('experience')}>clear</span>
                  </div>
                  <Dropdown
                    options={experienceOptions}
                    onSelect={(value) =>
                      handleFilterChange('experience', value)
                    }
                    disabled={false}
                    loading={false}
                    placeholder="Select"
                    className={styles.customDropdown}
                    value={selectedFilters.experience || ''}
                  />
                </div>
                <div className={styles.dropdownWrapper}>
                  <div className={styles.filterDropdownHeader}>
                    <p>App version</p>
                    <span onClick={() => clearFilter('appVersion')}>clear</span>
                  </div>
                  <Dropdown
                    options={versionOptions}
                    onSelect={(value) =>
                      handleFilterChange('appVersion', value)
                    }
                    disabled={false}
                    loading={false}
                    placeholder="Select"
                    className={styles.customDropdown}
                    value={selectedFilters.appVersion || ''}
                  />
                </div>
                <div className={styles.buttonWrap}>
                  <Button className={styles.resetBtn} onClick={resetFilters}>
                    Reset All
                  </Button>
                  <Button
                    className={styles.applyBtn}
                    onClick={applySelectedFilters}
                  >
                    Apply Now
                  </Button>
                </div>
              </div>
            )}
            <Button
              iconSrc={download}
              onClick={downloadCSV}
              className={styles.downloadButton}
            />
          </div>
        </div>
      </div>
      <div className={styles.tableContainer}>
        <table className={styles.userTable}>
          <thead>
            <tr>
              <th>Date/Time</th>
              <th>User details</th>
              <th>App version</th>
              <th>OS version</th>
              <th>Device model</th>
              <th>Experience</th>
              <th>Improvements</th>
              <th>Message</th>
            </tr>
          </thead>
          <tbody>
            {users?.length > 0 ? (
              users.map((user) => {
                const experience = experienceMapping[
                  user.lifeWinkExperience
                ] || {
                  label: 'Unknown',
                  styles: { backgroundColor: '#e2e3e5', color: '#6c757d' }, // Grey background for unknown
                };
                return (
                  <tr key={user.id}>
                    <td>
                      {format(new Date(user.createdAt), 'MM/dd/yyyy HH:mm')}
                    </td>
                    <td>{user.userDetails}</td>
                    <td>{user.appVersion}</td>
                    <td>{user.osVersion}</td>
                    <td style={{ width: '170px' }}>{user.deviceModel}</td>
                    <td>
                      <span
                        style={{
                          ...experience.styles,
                          padding: '5px 8px',
                          borderRadius: '8px',
                          display: 'inline-block', // Ensures proper spacing
                        }}
                      >
                        {experience.label}
                      </span>
                    </td>
                    <td>
                      {user.improvementAreas.map((area, index) => (
                        <span key={index}>
                          {area} <br />{' '}
                        </span>
                      ))}
                    </td>
                    <td>{user.suggestions}</td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan="7" className={styles.noFeedback}>
                  No feedback available.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {users?.length > 0 && (
        <div className={styles.pagination}>
          <Button
            disabled={currentPage.pageNumber === 1}
            onClick={() => {
              handlePageChange(currentPage.pageNumber - 1),
                setBackWordPage(true);
            }}
            className={styles.prevButton}
          >
            Previous
          </Button>
          <span>{`Page ${currentPage.pageNumber} of ${totalPages}`}</span>
          <Button
            disabled={currentPage.pageNumber === totalPages}
            onClick={() => {
              handlePageChange(currentPage.pageNumber + 1);
              setBackWordPage(false);
            }}
            className={styles.nextButton}
          >
            Next
          </Button>
        </div>
      )}
    </div>
  );
}

export default Feedback;
