import * as Yup from 'yup';

const loginValidationSchema = Yup.object({
  email: Yup.string()
    .email('Invalid email address')
    .required('Email is required'),
  password: Yup.string()
    .min(8, 'Password must be at least 8 characters')
    .matches(/[0-9]/, 'Password must contain at least 1 number')
    .matches(/[a-z]/, 'Password must contain at least 1 lowercase letter')
    .matches(/[A-Z]/, 'Password must contain at least 1 uppercase letter')
    .matches(
      /[!@#$%^&*()\-_+={}[\]:";'<>?,.~`|\\/ ]/,
      'Password must contain at least 1 special character or a space (non-leading/trailing)',
    )
    .matches(/^[^\s].*[^\s]$/, 'Password must not start or end with a space')
    .required('Password is required'),
  rememberMe: Yup.boolean(),
});
const forgotPasswordValidationSchema = Yup.object({
  email: Yup.string()
    .email('Invalid email address')
    .required('Email is required'),
});

const confirmCodeValidationSchema = Yup.object({
  confirmationCode: Yup.string()
    .required('OTP is required')
    .matches(/^\d+$/, 'OTP must be a number')
    .min(6, 'OTP should be at least 6 characters')
    .max(6, 'OTP should be at most 6 characters'),
});

const newPasswordValidationSchema = Yup.object({
  newPassword: Yup.string()
    .min(8, 'Password must be at least 8 characters')
    .matches(/[0-9]/, 'Password must contain at least 1 number')
    .matches(/[a-z]/, 'Password must contain at least 1 lowercase letter')
    .matches(/[A-Z]/, 'Password must contain at least 1 uppercase letter')
    .matches(
      /[!@#$%^&*()\-_+={}[\]:";'<>?,.~`|\\/ ]/,
      'Password must contain at least 1 special character or a space (non-leading/trailing)',
    )
    .matches(/^[^\s].*[^\s]$/, 'Password must not start or end with a space')
    .required('New password is required'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
    .required('Confirm password is required'),
});

export {
  loginValidationSchema,
  forgotPasswordValidationSchema,
  confirmCodeValidationSchema,
  newPasswordValidationSchema,
};
