import React from 'react';
import styles from './Button.module.css';

function Button({
  type = 'button',
  onClick,
  disabled = false,
  loading = false,
  className,
  iconSrc = null, // URL for PNG or other images
  iconAlt = 'icon', // Accessible alternative text
  iconPosition = 'left', // 'left' or 'right'
  children,
}) {
  return (
    <button
      type={type}
      onClick={onClick}
      disabled={disabled}
      className={`${styles.button} ${className}`}
    >
      {loading ? (
        'Loading...'
      ) : (
        <div className={styles.content}>
          {iconSrc && iconPosition === 'left' && (
            <img src={iconSrc} alt={iconAlt} className={styles.icon} />
          )}
          <span className={styles.text}>{children}</span>
          {iconSrc && iconPosition === 'right' && (
            <img src={iconSrc} alt={iconAlt} className={styles.icon} />
          )}
        </div>
      )}
    </button>
  );
}

export default Button;
