import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import { resetPassword, confirmResetPassword } from 'aws-amplify/auth';
import InputField from '../../components/inputField/InputField';
import Button from '../../components/button/Button';
import styles from './ForgotPassword.module.css';
import {
  forgotPasswordValidationSchema,
  confirmCodeValidationSchema,
  newPasswordValidationSchema,
} from '../../schemas/validationSchema';
import { useNavigate } from 'react-router-dom';
import AuthPage from '../../components/authPage/AuthPage';
import Toast from '../../components/toast/Toast';
import backArrow from '../../assets/lucide_arrow-up.svg';
function ForgotPassword() {
  const navigate = useNavigate();
  const [resetStep, setResetStep] = useState('email'); // Tracks the current step in the reset process
  const [email, setEmail] = useState('');
  const [confirmationCode, setConfirmationCode] = useState('');

  const sendOTP = async (email) => {
    try {
      const result = await resetPassword({
        username: email,
      });
      console.log('Forgot password:', result);
      Toast('success', 'We sent a code to your email');
      setResetStep('confirmationCode'); // Move to the confirmation code step
    } catch (err) {
      Toast('error', err.message);
      console.log('Error resetting password:', err.message);
    }
  };

  const handleForgotPassword = async (values, { setSubmitting }) => {
    setSubmitting(true);
    await setEmail(values.email);
    try {
      sendOTP(values.email);
    } catch (err) {
      Toast('error', err.message);
    }
  };

  const handleConfirmCode = async (values) => {
    setResetStep('confirmPassword');
    setConfirmationCode(values.confirmationCode);
  };

  const handleNewPassword = async (values, { setSubmitting }) => {
    try {
      const result = await confirmResetPassword({
        username: email,
        confirmationCode: confirmationCode,
        newPassword: values.newPassword,
      });
      console.log('Password reset confirmed:', result);
      setResetStep('done');
      Toast('success', 'Password reset successfully');
      navigate('/admin/login');
    } catch (err) {
      console.log('Error confirming code:', err.message);
      Toast('error', err.message);
    } finally {
      setSubmitting(false);
    }
  };
  const description =
    'LifeWink enables you to deliver life’s greatest gift - to love and be loved - by creating timeless private LifeWinks with your videos, photos, audio and attachments.';

  return (
    <>
      {resetStep === 'email' && (
        <AuthPage title="Forgot Password" description={description}>
          <Formik
            initialValues={{ email: '' }}
            validationSchema={forgotPasswordValidationSchema}
            onSubmit={handleForgotPassword}
          >
            {({ isSubmitting, errors, touched }) => (
              <Form className={styles.form}>
                <InputField
                  type="email"
                  name="email"
                  placeholder="Enter your email address"
                  label="Email address"
                  className={styles.input}
                  error={touched.email && errors.email}
                />
                <Button
                  type="submit"
                  disabled={isSubmitting}
                  loading={isSubmitting}
                  className={styles.button}
                >
                  Continue
                </Button>
                {errors.submit && (
                  <p className={styles.error}>{errors.submit}</p>
                )}
                <div
                  className={styles.backToLogin}
                  onClick={() => navigate('/admin/login')}
                >
                  <img src={backArrow} alt="" /> <span>Back to log in</span>
                </div>
              </Form>
            )}
          </Formik>
        </AuthPage>
      )}

      {resetStep === 'confirmationCode' && email && (
        <AuthPage title="Password Reset" description={description}>
          <div className={styles.subHeading}>
            {' '}
            <p>We sent a code to {email}</p>
            <p>Please enter your 6-digit code.</p>
          </div>
          <Formik
            initialValues={{ confirmationCode: '' }}
            validationSchema={confirmCodeValidationSchema}
            onSubmit={handleConfirmCode}
          >
            {({ isSubmitting, errors, touched }) => (
              <Form className={styles.form}>
                <InputField
                  type="text"
                  name="confirmationCode"
                  placeholder="Enter OTP"
                  label="Enter the OTP"
                  className={styles.input}
                  error={touched.confirmationCode && errors.confirmationCode}
                />

                <Button
                  type="submit"
                  disabled={isSubmitting}
                  loading={isSubmitting}
                  className={styles.button}
                >
                  Continue
                </Button>
                <div className={styles.resendContainer}>
                  <p>
                    Didn’t receive the email?{' '}
                    <span onClick={() => (email ? sendOTP(email) : null)}>
                      Resend
                    </span>
                  </p>
                </div>

                {errors.submit && (
                  <p className={styles.error}>{errors.submit}</p>
                )}
                <div
                  className={styles.backToLogin}
                  onClick={() => navigate('/admin/login')}
                >
                  <img src={backArrow} alt="" /> <span>Back to log in</span>
                </div>
              </Form>
            )}
          </Formik>
        </AuthPage>
      )}

      {resetStep === 'confirmPassword' && (
        <AuthPage title="Set new password" description={description}>
          <Formik
            initialValues={{ newPassword: '', confirmPassword: '' }}
            validationSchema={newPasswordValidationSchema}
            onSubmit={handleNewPassword}
          >
            {({ isSubmitting, errors, touched }) => (
              <Form className={styles.form}>
                <InputField
                  type="password"
                  name="newPassword"
                  placeholder="Enter new password"
                  label="New password"
                  className={styles.input}
                  error={touched.newPassword && errors.newPassword}
                  showPasswordToggle={true}
                />
                <InputField
                  type="password"
                  name="confirmPassword"
                  placeholder="Enter confirm password"
                  label="Confirm password"
                  className={styles.input}
                  error={touched.confirmPassword && errors.confirmPassword}
                  showPasswordToggle={true}
                />
                <Button
                  type="submit"
                  disabled={isSubmitting}
                  loading={isSubmitting}
                  className={styles.button}
                >
                  Reset password
                </Button>
                {errors.submit && (
                  <p className={styles.error}>{errors.submit}</p>
                )}
                <div
                  className={styles.backToLogin}
                  onClick={() => navigate('/admin/login')}
                >
                  <img src={backArrow} alt="" /> <span>Back to log in</span>
                </div>
              </Form>
            )}
          </Formik>
        </AuthPage>
      )}
    </>
  );
}

export default ForgotPassword;
