// components/AuthPage.js
import React from 'react';
import lifewinkLogo from '../../assets/login/lifewink_blue_logo.png';
import styles from './AuthPage.module.css';

function AuthPage({ children, title, description }) {
  return (
    <div className={styles.container}>
      <div className={styles.leftSide}>
        <div className={styles.characters}>
          <h3>
            LifeWink - Timeless, <br />
            Private Occasion-based Messages
          </h3>
          <p>{description}</p>
        </div>
      </div>
      <div className={styles.rightSide}>
        <div className={styles.rightWrapper}>
          <img src={lifewinkLogo} alt="Lifewink Logo" className={styles.logo} />
          <h2 className={styles.title}>{title}</h2>
          {children}
        </div>
      </div>
    </div>
  );
}

export default AuthPage;
