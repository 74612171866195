import React, { useState } from 'react';
import styles from './Layout.module.css';
import lifewinkLogo from '../../assets/navbar/logo.png';
import Feedback from '../../assets/navbar/feedback.svg';
import logout from '../../assets/navbar/log-out.svg';
import alertIcon from '../../assets/alert.svg';
import { signOut } from 'aws-amplify/auth';
import { useLocation, useNavigate } from 'react-router-dom';
import Modal from '../modal/Modal';
import Button from '../button/Button';
function Layout({ children }) {
  const navigate = useNavigate();
  const location = useLocation();
  const handleLogout = async () => {
    await signOut();
    navigate('/admin/login');
  };

  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
    document.body.classList.add('modal-open');
  };

  const closeModal = () => {
    setIsModalOpen(false);
    document.body.classList.remove('modal-open');
  };
  return (
    <div className={styles.container}>
      <div className={styles.sidebar}>
        <ul>
          <img src={lifewinkLogo} alt="" className={styles.logo} />
          <li
            className={` ${location.pathname === '/admin/feedback' ? styles.active : ''}`} // Add active class conditionally
          >
            <img
              src={Feedback}
              onClick={() => navigate('/admin/feedback')}
              alt=""
              className={styles.navIcon}
            />{' '}
            Feedback
          </li>
        </ul>
        <div className={styles.profile} onClick={openModal}>
          <span>Logout</span>
          <img src={logout} alt="logout" />
        </div>

        {isModalOpen && (
          <Modal closeModal={closeModal} showCloseButton={false}>
            <div className={styles.modalContent}>
              <img src={alertIcon} alt="" />
              <h2>Logout?</h2>
              <p>Are you sure you want to logout?</p>
            </div>
            <div className={styles.buttonWrapper}>
              <Button className={styles.yesButton} onClick={handleLogout}>
                Yes
              </Button>
              <Button className={styles.cancelButton} onClick={closeModal}>
                Cancel
              </Button>
            </div>
          </Modal>
        )}
      </div>
      <div className={styles.content}>{children}</div>
    </div>
  );
}

export default Layout;
