import React, { useEffect } from 'react';
import { Formik, Form } from 'formik';
import { signIn, fetchAuthSession } from 'aws-amplify/auth';
import InputField from '../../components/inputField/InputField';
import Button from '../../components/button/Button';
import styles from './Login.module.css';
import { loginValidationSchema } from '../../schemas/validationSchema';
import { useNavigate } from 'react-router-dom';
import AuthPage from '../../components/authPage/AuthPage';
import Toast from '../../components/toast/Toast';
function Login() {
  const navigate = useNavigate();

  const handleSignIn = async (values, { setSubmitting }) => {
    setSubmitting(true);
    try {
      const user = await signIn({
        username: values.email,
        password: values.password,
      });
      console.log('User:', user);
      const rememberMe = values.rememberMe;
      if (rememberMe) {
        localStorage.setItem('email', values.email);
        localStorage.setItem('password', values.password);
      } else {
        localStorage.removeItem('email');
        localStorage.removeItem('password');
      }
      if (user?.isSignedIn) {
        navigate('/admin/feedback');
      }
    } catch (err) {
      console.log('Error signing in:', err.message);
      Toast('error', err.message);
    } finally {
      setSubmitting(false);
    }
  };

  useEffect(() => {
    const fetchSessionAndUser = async () => {
      try {
        const session = await fetchAuthSession();
        if (session) {
          navigate('/admin/feedback');
        }
      } catch (error) {
        console.error('Error fetching session or current user:', error);
      }
    };

    fetchSessionAndUser();
  }, [navigate]);

  return (
    <AuthPage
      title="Log in to your account"
      description="LifeWink enables you to deliver life’s greatest gift - to love and be loved - by creating timeless private LifeWinks with your videos, photos, audio and attachments."
    >
      <Formik
        initialValues={{ email: '', password: '', rememberMe: false }}
        validationSchema={loginValidationSchema}
        onSubmit={handleSignIn}
      >
        {({ isSubmitting, errors, touched, setFieldValue }) => {
          useEffect(() => {
            const storedEmail = localStorage.getItem('email');
            const storedPassword = localStorage.getItem('password');

            if (storedEmail && storedPassword) {
              setFieldValue('email', storedEmail);
              setFieldValue('password', storedPassword);
              setFieldValue('rememberMe', true);
            } else {
              localStorage.removeItem('email');
              localStorage.removeItem('password');
            }
          }, [setFieldValue]);

          return (
            <Form className={styles.form}>
              <InputField
                type="email"
                name="email"
                placeholder="Enter your email address"
                label="Email address"
                className={styles.input}
                error={touched.email && errors.email}
              />
              <InputField
                type="password"
                name="password"
                placeholder="Enter your password"
                label="Password"
                className={styles.input}
                error={touched.password && errors.password}
                showPasswordToggle={true}
              />
              <div className={styles.rememberMeContainer}>
                <div style={{ display: 'flex' }}>
                  <InputField
                    type="checkbox"
                    name="rememberMe"
                    id="rememberMe"
                    className={styles.rememberMeCheckbox}
                  />
                  <label
                    htmlFor="rememberMe"
                    className={styles.rememberMeLabel}
                  >
                    Remember me
                  </label>
                </div>
                <div className={styles.forgotPasswordContainer}>
                  <a
                    href="/admin/forgot-password"
                    className={styles.forgotPasswordLink}
                  >
                    Forgot password?
                  </a>
                </div>
              </div>

              <Button
                type="submit"
                disabled={isSubmitting}
                loading={isSubmitting}
                className={styles.button}
              >
                Sign in
              </Button>
              {errors.submit && <p className={styles.error}>{errors.submit}</p>}
            </Form>
          );
        }}
      </Formik>
    </AuthPage>
  );
}

export default Login;
