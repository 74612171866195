import React from 'react';
import styles from './Dropdown.module.css'; // Assuming you've created a CSS module for the dropdown styles

function Dropdown({
  options = [],
  onSelect,
  disabled = false,
  loading = false,
  className,
  placeholder = 'Select',
  value = '',
}) {
  const handleChange = (event) => {
    const value = event.target.value;
    onSelect(value);
  };

  return (
    <select
      className={`${styles.dropdown} ${className}`}
      onChange={handleChange}
      value={value}
      disabled={disabled}
    >
      <option value="" disabled>
        {loading ? 'Loading options...' : placeholder}
      </option>
      {options.map((option, index) => (
        <option key={index} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
  );
}

export default Dropdown;
