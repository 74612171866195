import React, { useEffect, useRef, useState } from 'react';
import styles from './CustomDropdown.module.css';

function CustomDropdown({
  options = [],
  onSelect,
  onToggle,
  disabled = false,
  loading = false,
  className,
  placeholder = 'Select',
  value = '',
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState(value);
  const dropdownRef = useRef(null);

  const handleDropdownToggle = () => {
    if (!disabled) {
      setIsOpen(!isOpen);
    }
    if (onToggle) {
      onToggle();
    }
  };

  const handleOptionSelect = (option) => {
    setSelectedValue(option.value);
    onSelect(option.value);
    setIsOpen(false);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  return (
    <div
      className={`${styles.dropdownContainer} ${className}`}
      ref={dropdownRef}
    >
      <button
        className={`${styles.dropdown} ${disabled ? styles.disabled : ''}`}
        onClick={handleDropdownToggle}
        disabled={disabled}
      >
        {selectedValue
          ? options.find((option) => option.value === selectedValue)?.label
          : loading
            ? 'Loading options...'
            : placeholder}
      </button>
      {isOpen && (
        <ul className={styles.dropdownList}>
          {options.map((option) => (
            <li
              key={option.value}
              className={styles.dropdownItem}
              onClick={() => handleOptionSelect(option)}
            >
              {option.label}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}

export default CustomDropdown;
