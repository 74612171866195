/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  aws_project_region: process.env.REACT_APP_AWS_PROJECT_REGION,
  aws_cloud_logic_custom: [
    {
      name: 'checkTokenValidity',
      endpoint: 'https://pn2gt89tgc.execute-api.us-east-2.amazonaws.com/dev',
      region: process.env.REACT_APP_AWS_PROJECT_REGION, // Use env for region
    },
  ],
  aws_appsync_graphqlEndpoint:
    process.env.REACT_APP_AWS_APP_SYNC_GRAPHQL_ENDPOINT,
  aws_appsync_region: process.env.REACT_APP_AWS_PROJECT_REGION,
  aws_appsync_authenticationType: 'AWS_LAMBDA',
  aws_appsync_apiKey: process.env.REACT_APP_AWS_APP_SYNC_API_KEY,
  aws_cognito_identity_pool_id:
    process.env.REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID,
  aws_cognito_region: process.env.REACT_APP_AWS_COGNITO_REGION,
  aws_user_pools_id: process.env.REACT_APP_AWS_COGNITO_USER_POOLS_ID,
  aws_user_pools_web_client_id:
    process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID,
  oauth: {},
  aws_cognito_username_attributes: [
    process.env.REACT_APP_AWS_COGNITO_USERNAME_ATTRIBUTES,
  ],
  aws_cognito_social_providers: [],
  aws_cognito_signup_attributes: [
    process.env.REACT_APP_AWS_COGNITO_SIGNUP_ATTRIBUTES,
  ],
  aws_cognito_mfa_configuration:
    process.env.REACT_APP_AWS_COGNITO_MFA_CONFIGURATION,
  aws_cognito_mfa_types: [process.env.REACT_APP_AWS_COGNITO_MFA_TYPES],
  aws_cognito_password_protection_settings: {
    passwordPolicyMinLength: parseInt(
      process.env.REACT_APP_AWS_COGNITO_PASSWORD_PROTECTION_MIN_LENGTH,
      10,
    ),
    passwordPolicyCharacters: [],
  },
  aws_cognito_verification_mechanisms: [
    process.env.REACT_APP_AWS_COGNITO_VERIFICATION_MECHANISMS,
  ],
  aws_user_files_s3_bucket: process.env.REACT_APP_AWS_USER_FILES_S3_BUCKET,
  aws_user_files_s3_bucket_region:
    process.env.REACT_APP_AWS_USER_FILES_S3_BUCKET_REGION,
};

export default awsmobile;
