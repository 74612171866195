// src/api/apiClient.js
import axios from 'axios';

const apiClient = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${process.env.REACT_APP_AUTH_KEY}`, // Adjust as needed
  },
});

// Add a request interceptor if you need to include tokens dynamically
apiClient.interceptors.request.use(
  (config) => {
    // Modify config if needed (e.g., include dynamic auth tokens)
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

export default apiClient;
