import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';

const Toast = (severity, message, time) => {
  switch (severity) {
    case 'success':
      toast.success(message, {
        position: 'top-right',
        hideProgressBar: true,
        pauseOnFocusLoss: false,
        theme: 'colored',
        autoClose: time,
      });
      break;
    case 'error':
      toast.error(message, {
        position: 'top-right',
        hideProgressBar: true,
        pauseOnFocusLoss: false,
        theme: 'colored',
        toastId: message,
        autoClose: time,
      });
      break;
    case 'warn':
      toast.warn(message, {
        position: 'top-right',
        hideProgressBar: true,
        pauseOnFocusLoss: false,
        theme: 'colored',
        autoClose: time,
      });
      break;
    default:
      toast.info(message, {
        position: 'top-right',
      });
  }
};

export default Toast;
